import React from "react";
import { Bars } from "react-loader-spinner";
import { Link, Navigate } from "react-router-dom";

//** Lib's */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";
import { encryptionData } from "../../utils/Encryption";
//** Service's */
import ImageManagementDomainServices from "../../services/domainServices/ImageManagementDomainServices";

export default class ImageManagementList extends BaseComponent {

    //** Constructor  and Component method */
    constructor(props) {
        super(props);
        this.imageDomainServices = new ImageManagementDomainServices();
        this.state = {
            setIsLoad: false,
            dataList: [],
            pagination: {...this.imageDomainServices.pagination,
                openStatus: props.openStatus},
            searchList: this.imageDomainServices.dataItem
        };
        this.imageDomainServices.onUpdateState = this.updateState.bind(this);
        this.imageDomainServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
    
        this.imageDomainServices.onGetList(this.state.pagination);
    }


    handleChangeEvent = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ searchList: { ...detail } });
    };

    //** Action Method */
    onActionCall = (type, data) => {
        const encryptKey = encryptionData(data, 1);
        switch (type) {
            case "View":
                window.open(`/ImageManagementDetail/id?=${encryptKey}`, "_blank");
                break;
            default:
                // Handle default case here
                break;
        }
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <React.Fragment>
                <div className="top-search-add-button-sec mb-3">
                    <div className="row left-searchbar-sec">
                       
                    </div>
                    <div className="right-button-sec">
                        <Link to="/AddImageManagement" className="tab-title btn-hover color-3 active">
                           Add Image Generation Request<span>+</span>
                        </Link>
                    </div>
                </div>
                <div className="image-managment-list">
                    {this.state.setIsLoad ? (
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: "150px" }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={this.state.setIsLoad} />
                    ) : (
                        <Table
                            columns={this.imageDomainServices.gridColmns}
                            rows={this.state.dataList}
                            sortingColumns={[]}
                            pagination={this.state.pagination}
                            totalCount={this.state.TotalCount}
                            totalResultes={this.state.TotalCount}
                            setPagination={this.imageDomainServices.onPageChange.bind(this)}
                            customScope={[
                                {
                                    column: "imageGenerationRequestID",
                                    renderTableData: this.imageDomainServices.gridActionsViewCol.bind(this)
                                }
                            ]} />
                    )}
                </div>
            </React.Fragment>
        )
    }
}