//** Component  */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";

//** Services */

//*** Lib */
import { Navigate } from "react-router";
import { Bars } from "react-loader-spinner";
import RequestGroupDomainServices from "../../services/domainServices/requestGroupServices";
import { encryptionData } from "../../utils/Encryption";

export default class RPARequestGroup extends BaseComponent {
    constructor(props) {
        super(props);
        this.requestGroupServices = new RequestGroupDomainServices();
        this.state = {
            pagination: {
                ...this.requestGroupServices.pagination,
                openStatus: props.openStatus,
                // requestTypeList: "0",
            },
            setIsLoad: false,
            dataList: [],
        };
        this.requestGroupServices.onUpdateState = this.updateState.bind(this);
        this.requestGroupServices.onActionCall = this.onActionCall.bind(this);
    }
    //** Constructor  and Component method */
    componentDidMount() {
        this.requestGroupServices.onGetRequests(this.state.pagination);
    }

    onActionCall = (type, data) => {
        // *** getByID encryption ***//
        const encryptKey = encryptionData(data, 1);
        switch (type) {
            case "View":
                window.open(`/RPARequestGroupDetails/id?=${encryptKey}`, "_blank");
                break;
                default:
                    // Handle default case here
                    break;
        }
    };


    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <>
                <div className="rpa-request-group-list">
                    {this.state.setIsLoad ? (
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: "150px" }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={this.state.setIsLoad}
                        />
                    ) : (
                        <Table
                            columns={this.requestGroupServices.gridColmns}
                            rows={this.state.dataList}
                            sortingColumns={[]}
                            pagination={this.state.pagination}
                            totalCount={this.state.TotalCount}
                            totalResultes={this.state.TotalCount}
                            setPagination={this.requestGroupServices.onPageChange.bind(this)}
                            customScope={[
                                {
                                    column: "middlewareRequestGroupId",
                                    renderTableData:
                                        this.requestGroupServices.gridActionsViewCol.bind(this),
                                },
                            ]}
                        />
                    )}
                </div>
            </>
        );
    }
}
