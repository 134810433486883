import { Navigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
//** Lib's */
import Button from "../../components/common/Button";
import BaseComponent from "../../shared/baseComponent";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";
//** Service's */
import ImageManagementDomainServices from "../../services/domainServices/ImageManagementDomainServices";

export default class AddImageManagement extends BaseComponent {

    //** Constructor  and Component method */
    constructor(props) {
        super(props);
        this.imageDomainServices = new ImageManagementDomainServices();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            setIsLoad: false,
            imageNameList: [],
            formField: this.imageDomainServices.formDataItem
        }
        this.imageDomainServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        this.imageDomainServices.onImageList();
        this.imageDomainServices.onInitService();
    }

    //** Handle Changes */
    handleChange(name, value) {
        let detail = this.state.formField;
        detail[name] = value;
        this.setState({ formField: detail });
    }

    //** API's Call */
    addImageGenration = () => {
    
        if (this.imageDomainServices.isValidSubmit(this.state.formField, this.state.validationRules, this.state.validState)) {
            let request = {
            
              imageGenerationRequestTypeID:this.state.formField.imageGenerationRequestTypeID,
                isRpaRequest:true,
                createdBy: this.imageDomainServices.authUser.adminUserId,
            }
            this.imageDomainServices.onAdd(request);
        }
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="mb-4">
                <div className="accordion" id="accordionExample">
                    <div className="card accordion-item active">
                        <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <div className="card-body">
                                    {this.state.setIsLoad ?
                                        <Bars
                                            className="row"
                                            wrapperStyle={{ marginTop: "150px" }}
                                            color="#3c4b64"
                                            height={55}
                                            width="100%"
                                            visible={this.state.setIsLoad}
                                        /> :
                                        <div className="row mb-4">
                                            {this.imageDomainServices.formFields.map((Item, index) => {
                                                console.log(this.state.formField[Item.fieldName]);
                                                
                                                return (
                                                    <>
                                                        <FormFieldGroup
                                                            column={Item}
                                                            value={this.state.formField[Item.fieldName]}
                                                            error={this.state.validState.error[Item.fieldName]}
                                                            options={Item.options ? this.state.imageNameList : null}
                                                            onChange={ (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                            key={index}
                                                            onBlur={() => this.imageDomainServices.validateField(Item.fieldName, this.state.formField, this.state.validationRules, this.state.validState)}
                                                        />
                                                    </>
                                                );

                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="addnewuser-button mb-4">
                                <button className="tab-title tab-title--active  btn-hover  color-3 active" onClick={() => this.addImageGenration()}> Save </button>
                                <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.imageDomainServices.onRedirectoList()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}