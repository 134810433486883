
import { DataFormat } from "../../utils/DateFormat";
import { ErrorMessage, SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import BaseDomainService from "./baseDomainServices";

const supplierDetail = {
  ComparisonType: "Price",
  RequestName: "",
  SourceInputFileName: "",
  DestinationInputFileName: "",
  SourceInputFile: null,
  DestinationInputFile: null,
  CreatedBy: 1,
};

export default class SupplierComparisonServices extends BaseDomainService {
  // API Get
  add = this.apiService.supplierComparisonServices.addSupplierComparison;
  supplierList = this.apiService.supplierComparisonServices.getSupplierComparisonList;
  getSupplierComparisonById =
    this.apiService.supplierComparisonServices.getSupplierComparisonById;

  // Table Grid columns
  gridColmns = [
    { name: "requestName", title: "Request Name" },
    { name: "comparisonType", title: "Comparison Type" },
    { name: "startDate", title: "Start Date" },
    { name: "endDate", title: "End Date" },
    { name: "statusName", title: "Status" },
    { name: "supplierComparisonId", title: "Actions" },
  ];

  // Define the state variables
  dataItem;

  // define the form fields

  formFields = [
    {
      inputType: "text",
      tittle: "Request Name",
      placeholder: "Request Name",
      fieldName: "RequestName",
      className: 'col-md-4',
      maxLength: 200,
      validation: [{ type: "require" }]
    },
    {
      inputType: "select",
      tittle: "Comparison Type",
      placeholder: "",
      fieldName: "ComparisonType",
      isDefault: true,
      Options: [
        { name: "Price" },
        { name: "Stock" },
      ],
      className: "col-md-4",
    },
    {
      inputType: "file",
      tittle: "Old File",
      placeholder: "Input File",
      fieldName: "SourceInputFile",
      subRequest: "1",
      className: 'col-md-4 PartielFile',
      validation: [{ type: "require" }]
    },
    {
      inputType: "file",
      handle: "true",
      tittle: "New File",
      placeholder: "Input File",
      fieldName: "DestinationInputFile",
      subRequest: "1",
      className: 'col-md-4 PartielFile',
      validation: [{ type: "require" }]
    },
  ];

  constructor() {
    super();
    this.onPaginationUpdate = this.onGetSupplierList.bind(this);
    this.actionKey = "supplierComparisonId";
    this.dataItem = { ...supplierDetail };
  }

  //** API Calls

  onAdd = (request) => {
    this.apiService.onAPICall(
      "addSupplierComparison",
      this.add,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };
  onGetSupplierList = (request) => {
        this.apiService.onAPICall(
      "getSupplierComparisonList",
      this.supplierList,
      request,
      this.onAPIResponse,
      this.onUpdateState
    );
  };
  
  
  onGetSupplierComparisonById = (requst) => {
    this.apiService.onAPICall(
      "getSupplierComparisonById",
      this.getSupplierComparisonById,
      requst,
      this.onAPIResponse,
      this.onUpdateState
    );
  };


  //** Service Event */

  validationRules = {}

  onInitService() {
    //This method only call when we use form component and we can add all items which we on on form load
    let valRule = getValidationRuleList(this.formFields);
    this.validationRules = valRule;
    this.onUpdateState({
      validationRules: valRule,
    });
  }

  //** Methods     */

  onResetData = () => {
    this.dataItem = { ...supplierDetail };
    let resetValidation = {
      isValid: false,
      error: {},
    };
    this.onUpdateState({
      supplierDetail: this.dataItem,
      validState: resetValidation,
    });
  };

  onRedirectoList = () => {
    this.onRedirect("/SupplierComparisonList");
  };

  onAPIResponse = (key, isSuccess, responseData) => {

    if (!isSuccess) {
      this.onUpdateState({ setIsLoad: false });
      return;
    }
    switch (key) {
      case "addSupplierComparison":
        let data = responseData;
        if (data.errorType === 'EXIST') {
          this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", data.errorColumn))
        } else {
          this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Supplier Comparison"));
          this.onRedirectoList();
        }
        this.onUpdateState({ "setIsLoad": false });
        break;
      case "getSupplierComparisonList":
        let itemList = responseData.itemList;
        if (responseData !== null) {
          itemList.forEach(function (i) {
            i.startDate = i.startDate
              ? DataFormat(
                i.startDate
              )
              : "-";
            i.endDate = i.endDate
              ? DataFormat(
                i.endDate
              )
              : "-";
            i.ComparisonType =
              i.ComparisonType === 1
                ? "Price"
                : i.ComparisonType === 2
                  ? "Stock"
                  : null;
          })
          this.onUpdateState({
            "getList": itemList,
            "setIsLoad": false,
            "TotalCount": responseData.totalCount,
            
          })
        }
        break;
      case "getSupplierComparisonById":
    
          if (responseData != null) {
            responseData.statusName = responseData.statusName ? responseData.statusName : "NA"
            responseData.supplierComparisonId = responseData.supplierComparisonId ? responseData.supplierComparisonId : "0"
            responseData.startDate = responseData.startDate ? DataFormat(responseData.startDate) : "NA"
            responseData.sourceInputFileName = responseData.sourceInputFileName ? responseData.sourceInputFileName : "0"
            responseData.sourceCount = responseData.sourceCount ? responseData.sourceCount : "0"
            responseData.requestName = responseData.requestName ? responseData.requestName : "0"
            responseData.outputFileName = responseData.outputFileName ? responseData.outputFileName : "NA"
            responseData.outputCount = responseData.outputCount ? responseData.outputCount : "NA"
            responseData.errorMessage = responseData.errorMessage ? responseData.errorMessage : "NA"
            responseData.endDate = responseData.endDate ? DataFormat(responseData.endDate) : "NA"
            responseData.destinationInputFileName = responseData.destinationInputFileName ? responseData.destinationInputFileName : "NA"
            responseData.destinationCount = responseData.destinationCount ? responseData.destinationCount : "NA"
            responseData.createdDate = responseData.createdDate ? DataFormat(
              responseData.createdDate
            ) : "NA"
          this.onUpdateState({
            supplierDetals: responseData
          })

        }
        this.onUpdateState({
          "setIsLoad": false
        });
        break;
    }
  };
}
