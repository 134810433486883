import React from 'react'
import FormFieldGroup from '../../components/UI Element/FormGrop/FormFieldGroup'
import ButtonLoader from '../../components/common/buttonLoader/ButtonLoader'
import Button from '../../components/common/Button';
import BaseComponent from '../../shared/baseComponent';
import productDataSyncDomainServices from '../../services/domainServices/productDataSyncServices';
import { ErrorMessage } from '../../utils/Messages';
import { Navigate } from 'react-router-dom';
import { DownloadInputFile } from '../../services/axiosServices/ApiEndPoints';

export default class AddNewProductData extends BaseComponent {

    constructor(props) {
        super(props)
        this.productDataSyncServices = new productDataSyncDomainServices();
        this.productDataSyncServices.onUpdateState = this.updateState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            ...this.state,
            setIsLoad: false,
            operationalFormField: this.productDataSyncServices.dataItem,
            setModuleList: [],
        }
    }

    handleChange(name, value) {
        if (value === "4") {
            let detail = this.state.operationalFormField;
            detail["Mode"] = "Manual";
            this.setState({ operationalFormField: { ...detail } });
        }
        let detail = this.state.operationalFormField;
        detail[name] = value;
        this.setState({ operationalFormField: { ...detail } });
    }

    handleFileChange(event) {
        const { name, value } = event.target;
        const File = event.target.files[0];

        if (File && (File.type === "text/csv" || File.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            if (File.size > 0) {
                const importFile = {
                    ...this.state.operationalFormField,
                    [name]: value,
                    inputFile: File.name,
                    inputScriptFile: File,
                    FileName: File.name, // Add FileName property for PDF
                };

                this.setState({ operationalFormField: importFile });
            }
        } else {
            this.productDataSyncServices.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidFileTypeCSV);
        }
    }

    proceed = () => {
        let detail = this.state.operationalFormField;
        let isValid = this.productDataSyncServices.isValidSubmit(detail, this.state.validationRules, this.state.validState)
        if (isValid) {
            if (detail.Mode === "Sync") {
                this.add();
            }
            else if (detail.Mode === "Manual") {
                if ((detail.File === null) && detail.FileName === "") {
                    this.productDataSyncServices.apiService.serviceManager.swalServices.Error(ErrorMessage.InputFile);
                }
                else {
                    this.add();
                }

            }
        }
    }

    add = () => {
        let request = {
            Name: this.state.operationalFormField.Name,
            Mode: this.state.operationalFormField.Mode,
            File: this.state.operationalFormField.inputScriptFile ? this.state.operationalFormField.inputScriptFile : null,
            FileName: this.state.operationalFormField.FileName,
            ProductDataSyncImportTypeId: this.state.operationalFormField.productDataSyncImportTypeId,
            CreatedBy: this.state.operationalFormField.CreatedBy,
            IsPubChemScheduler: false
        }
        this.productDataSyncServices.onAdd(request);
    }

    componentDidMount() {
        this.productDataSyncServices.onInitService();
        this.productDataSyncServices.onProductDataSyncImportType();
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect} />
        }
        return (
            <div>
                <div className="mb-4">
                    {this.state.operationalFormField.Mode === "Manual" ?
                        <div className='d-flex justify-content-end align-items-center mb-3 mr-2'>
                            <b>
                                DownLoad Template :
                            </b>
                            <span for="requestName" className="detail-desc ml-3">
                                <a href={`${DownloadInputFile + `?FileName=ProductDataSync.csv&ModuleType=Templates`}`} target="_top"> <i className="fa-2x fa fa-download"></i></a>
                            </span>
                        </div> : null
                    }
                    <div className="accordion" id="accordionExample">
                        <div className="card accordion-item active">
                            <div id="accordionOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="card-body">
                                        <div className="row mb-4">
                                            {this.productDataSyncServices.formFields.map((Item, index) => {
                                                return (
                                                    <>
                                                        {/* Conditionally render based on serverLocationId */}
                                                        {Item.fieldName === "File" && this.state.operationalFormField.Mode !== "Manual" ? null : (
                                                            <FormFieldGroup
                                                                column={Item}
                                                                value={this.state.operationalFormField[Item.fieldName]}
                                                                error={this.state.validState.error[Item.fieldName]}
                                                                // options={Item.Options ? Item.Options : this.state.setModuleList}

                                                                options={Item.Options ? Item.Options.filter(option => {
                                                                    // Filter out "Sync" option if productDataSyncImportTypeId is "4"
                                                                    return this.state.operationalFormField.productDataSyncImportTypeId !== "4" || option.name !== "Sync";
                                                                }) : this.state.setModuleList}

                                                                onKeyDown={Item.onkeydown ? Item.onkeydown.bind(this) : null}
                                                                onChange={Item.inputType === 'checkbox' ? (event) => this.handleChange(Item.fieldName, event.target.checked)
                                                                    : Item.inputType === "file" ? (event) => this.handleFileChange(event) :
                                                                        Item.inputType === "select" || Item.inputType === "text" ? (event) => this.handleChange(Item.fieldName, event.target.value)
                                                                            : (event) => this.handleChange(Item.fieldName, event.target.value)}
                                                            />
                                                        )}

                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="addnewuser-button mb-4">
                                    <button
                                        className="tab-title tab-title--active  btn-hover  color-3 active"
                                        onClick={() => this.proceed()}
                                    >
                                        {this.state.setIsLoad ? <ButtonLoader /> : "Save"}
                                    </button>
                                    <Button type='cancel' title="Cancel" className="ml-2" onClick={() => this.productDataSyncServices.onRedirectoList()} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
