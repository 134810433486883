import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar(props) {
  const location = useLocation();
  const [isAddRequest, setAddRequest] = useState(false);
  const [issiteManage, setSiteManage] = useState(false);
  const [isAdddataAnalyzer, setAdddataAnalyzer] = useState(false);
  const [isAddNewProductData, setAddNewProductData] = useState(false);
  const [isRPAData, setRPAData] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const closeAllMenus = () => {
    setAddRequest(false);
    setAdddataAnalyzer(false);
    setAddNewProductData(false);
    setRPAData(false);
    setSiteManage(false);
  };
  const handleClick = () => {
    setIsActive(!isActive);
  };
  const toggleAddRequest = () => {
    closeAllMenus();
    setAddRequest(!isAddRequest);
    handleClick();
  };
  const toggleSiteManage = () => {
    closeAllMenus();
    setSiteManage(!issiteManage);
    handleClick();
  };
  const toggleRPAData = () => {
    closeAllMenus();
    setRPAData(!isRPAData);
  };
  const toggleAddNewProductData = () => {
    closeAllMenus();
    setAddNewProductData(!isAddNewProductData);
  };
  const toggleAdddataAnalyzer = () => {
    closeAllMenus();
    setAdddataAnalyzer(!isAdddataAnalyzer);
  };

  return (
    <aside
      id="layout-menu"
      className={`layout-menu menu-vertical menu bg-menu-theme ${props.isHighlighted ? "Drawer-Sidebar-highlighted" : ""
        }`}
    >
      <div className="sidebar-section-main">
        <div className="app-brand demo">
          <Link to="/Dashboard" className="app-brand-link">
            <div>
              <span className="app-brand-logo demo"></span>
              <span className="app-brand-text demo menu-text fw-bolder">
                {" "}
                RPA Middleware
              </span>
            </div>
          </Link>
          <div
            className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none"
            onClick={() => props.handleIconClick()}
          >
            <div className="nav-item nav-link px-0 me-xl-4">
              <i className="bx bx-x bx-sm"></i>
            </div>
          </div>
        </div>
        <div className="menu-list-section">
          {/* Your sidebar content */}
          <ul className="list-style-type">

            <li className={location.pathname === "/Dashboard" ? "active" : ""}>
              <Link to="/Dashboard" onClick={closeAllMenus}>
                <div>
                  <i className="menu-icon far fa-folder"></i>
                  <span>Dashboard</span>
                </div>
              </Link>
            </li>

            <li className="dropdown-menu-sec">
              <Link onClick={toggleRPAData} to="/RPARequest"
                className={
                  location.pathname === "/RPARequest"
                    ? "active-one"
                    : "" || location.pathname === "/RPARequest"
                      ? "active-one"
                      : "" || location.pathname === "/RPARequestGroup"
                        ? "active-one"
                        : ""
                          || location.pathname === "/AddPartialRequest"
                          ? "active-one"
                          : "" || location.pathname === "/AddRequest"
                            ? "active-one"
                            : "" || location.pathname === "/AddLinkBuilder"
                              ? "active-one"
                              : "" || location.pathname === "/ManageLinkBuilder"
                                ? "active-one"
                                : "" || location.pathname === "/LogTable"
                                  ? "active-one"
                                  : "" || location.pathname === "/RequestDetails/id"
                                    ? "active-one"
                                    : ""
                }>
                <div>
                  <i class="menu-icon fa-regular fa-hand"></i>
                  <span>RPA Processes</span>
                </div>
                <i className="bx bxs-chevron-down arrow"></i>
              </Link>
              {isRPAData && (
                <ul className="list-style-typee">
                  <li className={location.pathname === "/RPARequest" ? "submenu-active " : ""}>
                    <Link to="/RPARequest">RPA Request</Link>
                  </li>
                  <li className={location.pathname === "/RPARequestGroup" ? "submenu-active " : ""}>
                    <Link to="/RPARequestGroup">RPA Request Group</Link>
                  </li>
                  <li className={location.pathname === "/ManageLinkBuilder" ? "submenu-active" : ""}>
                    <Link to="/ManageLinkBuilder">Manage Link Builder</Link>
                  </li>
                  <li className={location.pathname === "/LogTable" ? "submenu-active" : ""}>
                    <Link to="/LogTable">Logs</Link>
                  </li>
                </ul>
              )}
            </li>

            <li className="dropdown-menu-sec">
              <Link
                onClick={toggleAdddataAnalyzer}
                to="/PriceComparison"
                className={
                  location.pathname === "/PriceComparison"
                    ? "active-one"
                    : "" || location.pathname === "/PriceValidation"
                      ? "active-one"
                      : "" || location.pathname === "/StockValidation"
                        ? "active-one"
                        : "" || location.pathname === "/DataAnalyzerList"
                          ? "active-one"
                          : "" || location.pathname === "/DatabaseAnalyzerDetails/id"
                            ? "active-one"
                            : ""|| location.pathname === "/SupplierComparisonList"
                            ? "active-one"
                            : ""
                }
              >
                <div>
                  <i className="menu-icon fa fa-database"></i>
                  <span>Database Analyzer</span>
                </div>
                <i className="bx bxs-chevron-down arrow"></i>
              </Link>
              {isAdddataAnalyzer && (
                <ul className="list-style-typee">
                  <li
                    className={
                      location.pathname === "/PriceComparison"
                        ? "submenu-active "
                        : ""
                    }
                  >
                    <Link to="/PriceComparison">Price Comparison</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/PriceValidation"
                        ? "submenu-active"
                        : ""
                    }
                  >
                    <Link to="/PriceValidation">Price Validation</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/StockValidation"
                        ? "submenu-active"
                        : ""
                    }
                  >
                    <Link to="/StockValidation">Stock Validation</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/DataAnalyzerList"
                        ? "submenu-active"
                        : ""
                    }
                  >
                    <Link to="/DataAnalyzerList">Data Analyzer List</Link>
                  </li>
                  <li
                    className={
                      location.pathname === "/SupplierComparisonList"
                        ? "submenu-active"
                        : ""
                    }
                  >
                    <Link to="/SupplierComparisonList">Supplier Comparison</Link>
                  </li>
                </ul>
              )}
            </li>

            <li className="dropdown-menu-sec">
              <Link onClick={toggleAddNewProductData} to="/ChemIndexDashBoard"
                className={
                  location.pathname === "/ChemIndexDashBoard"
                  ? "active-one"
                  : "" ||location.pathname === "/SyncData"
                    ? "active-one"
                    : "" || location.pathname === "/SyncData"
                      ? "active-one"
                      : "" || location.pathname === "/AddNewProductData"
                        ? "active-one"
                        : "" || location.pathname === "/ProductImport"
                          ? "active-one"
                          : "" || location.pathname === "/AddProductImport"
                            ? "active-one"
                            : "" || location.pathname === "/ManageRegulationRequest"
                              ? "active-one"
                              : "" || location.pathname === "/AddRegulationRequest"
                                ? "active-one"
                                : "" || location.pathname === "/ManageRegulation"
                                  ? "active-one"
                                  : "" || location.pathname === "/AddLinkupRequest"
                                    ? "active-one"
                                    : "" || location.pathname === "/ManageLinkupRequest"
                                      ? "active-one"
                                      : "" || location.pathname === "/AddRegulation"
                                        ? "active-one"
                                        : ""}>
                <div>
                  <i className="menu-icon fas fa-sync"></i>
                  <span>ChemIndex</span>
                </div>
                <i className="bx bxs-chevron-down arrow"></i>
              </Link>
              {isAddNewProductData && (
                <ul className="list-style-typee">
                    <li className={location.pathname === "/ChemIndexDashBoard" ? "submenu-active " : ""}>
                    <Link to="/ChemIndexDashBoard">ChemIndex DashBoard</Link>
                  </li>
                  <li className={location.pathname === "/SyncData" ? "submenu-active " : ""}>
                    <Link to="/SyncData">Sync Data</Link>
                  </li>
                  <li className={location.pathname === "/ProductImport" ? "submenu-active" : ""}>
                    <Link to="/ProductImport">Product Import</Link>
                  </li>
                  <li className={location.pathname === "/ManageRegulationRequest" ? "submenu-active" : ""}>
                    <Link to="/ManageRegulationRequest">Regulation Request</Link>
                  </li>
                  <li className={location.pathname === "/ManageRegulation" ? "submenu-active" : ""}>
                    <Link to="/ManageRegulation">Regulations</Link>
                  </li>
                  <li className={location.pathname === "/ManageLinkupRequest" ? "submenu-active" : ""}>
                    <Link to="/ManageLinkupRequest">Linkup Request</Link>
                  </li>
                </ul>
              )}
            </li>

            <li className="dropdown-menu-sec" id="containerr">
              <Link onClick={toggleAddRequest} to="/RPAModules"
                className={
                  // {isActvie ? 'activeClass' : ''}
                  location.pathname === "/RPAModules"
                    ? "active-one"
                    : "" || location.pathname === "/RPAModules"
                      ? "active-one"
                      : "" || location.pathname === "/AddNewComponent"
                        ? "active-one"
                        : "" || location.pathname === "/ServerClientConfigurations"
                          ? "active-one"
                          : "" || location.pathname === "/AddServerConfigurations"
                            ? "active-one"
                            : "" || location.pathname === "/CodeManagement"
                              ? "active-one"
                              : "" || location.pathname === "/AddScript"
                                ? "active-one"
                                : "" || location.pathname === "/ManageUsers"
                                  ? "active-one"
                                  : "" || location.pathname === "/AddNewUsers"
                                    ? "active-one"
                                    : "" || location.pathname === "/ManageServer"
                                      ? "active-one"
                                      : "" || location.pathname === "/AddServer"
                                        ? "active-one"
                                        : ""} >
                <div>
                  <i className="menu-icon fa fa-gears"></i>
                  <span>Configuration</span>
                </div>
                <i className="bx bxs-chevron-down arrow collapsed click d-block"></i>
              </Link>

              {isAddRequest && (
                <ul className="list-style-typee">
                  <li className={location.pathname === "/RPAModules" ? "submenu-active" : ""}>
                    <Link to="/RPAModules ">RPA Module</Link>
                  </li>
                  <li className={location.pathname === "/ServerClientConfigurations" ? "submenu-active" : ""}>
                    <Link to="/ServerClientConfigurations ">Server Client</Link>
                  </li>
                  <li className={location.pathname === "/CodeManagement" ? "submenu-active" : ""}>
                    <Link to="/CodeManagement ">Code Management</Link>
                  </li>
                  <li className={location.pathname === "/ManageUsers" ? "submenu-active" : ""}>
                    <Link to="/ManageUsers ">Manage Users</Link>
                  </li>
                  <li className={location.pathname === "/ManageServer" ? "submenu-active" : ""}>
                    <Link to="/ManageServer ">Manage Server</Link>
                  </li>
                </ul>
              )}
            </li>

            <li className="dropdown-menu-sec" id="containerr">
              <Link onClick={toggleSiteManage} to="/Exception"
                className={
                  // {isActvie ? 'activeClass' : ''}
                  location.pathname === "/Exception"
                    ? "active-one"
                    : "" || location.pathname === "/FileManager"
                      ? "active-one"
                      : "" || location.pathname === "/ExceptionDetails/id"
                        ? "active-one"
                        : ""
                } >
                <div>
                  <i className="menu-icon fa fa-file"></i>
                  <span>Site Management</span>
                </div>
                <i className="bx bxs-chevron-down arrow collapsed click d-block"></i>
              </Link>

              {issiteManage && (
                <ul className="list-style-typee">
                  <li className={location.pathname === "/Exception" ? "submenu-active" : ""}>
                    <Link to="/Exception ">Exception</Link>
                  </li>
                  <li className={location.pathname === "/FileManager" ? "submenu-active" : ""}>
                    <Link to="/FileManager ">File Manager</Link>
                  </li>
                </ul>
              )}
            </li>

            <li
              className={
                location.pathname === "/AddEmail"
                  ? "active"
                  : "" || location.pathname === "/EmailValidation"
                    ? "active"
                    : "" || location.pathname === "/AddEmail/id"
                      ? "active"
                      : ""
                // || location.pathname === "/LinkBuilderDetail/id"
                //   ? "active"
                //   : ""
              }>
              <Link to="/EmailValidation" onClick={closeAllMenus}>
                <div>
                  <i className="menu-icon fa-solid fa fa-envelope"></i>
                  <span>Email Validation</span>
                </div>
              </Link>
            </li>

            <li
              className={
                location.pathname === "/AddBingoProductImport"
                  ? "active"
                  : "" || location.pathname === "/BingoProductImportList"
                    ? "active"
                    : "" || location.pathname === "/AddBingoProductImport/id"
                      ? "active"
                      : ""
              }>
              <Link to="/BingoProductImportList" onClick={closeAllMenus}>
                <div>
                  <i className="menu-icon fa-solid fa fa-envelope"></i>
                  <span>Bingo Product Import</span>
                </div>
              </Link>
            </li>
          {/* Image Management menu */}
            <li
              className={
                location.pathname === "/AddImageManagement"
                  ? "active"
                  : "" || location.pathname === "/ImageManagementList"
                    ? "active"
                    : "" || location.pathname === "/ImageManagementDetail/id"
                    ? "active"
                    : ""
              }>
              <Link to="/ImageManagementList" onClick={closeAllMenus}>
                <div>
                  <i className="menu-icon fa fa-file"></i>
                  <span>Image Management</span>
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <footer className="footer-section footer-top-sidebar mt-5">
          <div className="copyright-area">
            <div className="container">
              <div className="">
                <div className=" text-center ">
                  <div className="copyright-text">
                    <p className="colorName text-center">
                      <a href="/Dashboard">RPA Middleware System</a> <br />
                      &copy; {new Date().getFullYear()} All Right Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div >
    </aside >
  );
}
