import BaseDomainService from "./baseDomainServices";
import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
const intialFormFields = {
    imageGenerationRequestTypeID: "",
}

export default class ImageManagementDomainServices extends BaseDomainService {
    
    add = this.apiService.imageManagementServices.addImageGenerationRequests;
    imageList = this.apiService.imageManagementServices.getAllImageGenerationRequestType;
    getList = this.apiService.imageManagementServices.getAllImageGenerationRequestsList;
    getById = this.apiService.imageManagementServices.getImageGenerationRequestsByImageGenerationRequestID;


    gridColmns = [
        { name: 'imageGenerationRequestName', title: 'Request' },
        { name: 'imageGenerationRequestTypeName', title: 'project Name' },
        { name: 'statusName', title: 'Status Name' },
        { name: 'totalImagesBeforeProcess', title: 'Images Before Process' },
        { name: 'totalImagesAfterProcess', title: 'Images After Process' },
        { name: 'newImageCount', title: 'New Image Count' },
        { name: 'imageGenerationRequestID', title: 'Action' },
    ];
    formFields = [
        {
            inputType: "select",
            tittle: "Image Management",
            placeholder: "Select Image",
            fieldName: "imageGenerationRequestTypeID",
            options: [],
            optionFieldName: "imageGenerationRequestTypeID",
            className: 'col-md-6',
            validation: [{ type: "require" }]
        },
       
    ];

    onAdd = (request) => {
      
        this.apiService.onAPICall("add", this.add, request, this.onAPIResponse, this.onUpdateState);
    }
    onImageList = (request) => {
        this.apiService.onAPICall("imageList", this.imageList, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetList = (requst) => {
        
        this.apiService.onAPICall("getList", this.getList, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetById = (requst) => {
      
        this.apiService.onAPICall("getById", this.getById, requst, this.onAPIResponse, this.onUpdateState);
    }
    
    dataList;
    formDataItem;
    constructor() {
        super();
        this.actionKey = "imageGenerationRequestID";

        this.formDataItem = { ...intialFormFields };
     this.onPaginationUpdate = this.onGetList.bind(this);
    }

    onInitService() {
        let valRule = getValidationRuleList(this.formFields);
        this.validationRules = valRule;
        this.validationRulesForType = valRule
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule,
        });
    }

    onRedirectoList = () => {
        this.onRedirect('/ImageManagementList');
    };
    onAPIResponse = (key, isSuccess, responseData, getRequest) => {
      
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "add":
                if (responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Success(responseData.errorMessage + " and " + responseData.errorType);
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Image Genration Success"));
                    this.onRedirectoList();
                }
                this.onUpdateState({ setIsLoad: false });
                break;
                case "getList":
                  
                    if (responseData.itemList !== null) {
                        let itemList = responseData.itemList;
                    }
                    this.onUpdateState({
                        dataList: responseData.itemList,
                        TotalCount: responseData.totalCount,
                        setIsLoad: false
                    });
                    break;
            case "imageList":
                
                if (responseData !== null) {
                    let getList = responseData;
                    let dataList = getList?.map((data) => ({
                        id: data.imageGenerationRequestTypeID,
                        name: data.imageGenerationRequestTypeName,
                    }));
                    this.onUpdateState({
                        imageNameList: dataList
                    });
                }
                this.onUpdateState({ setIsLoad: false });
                break;
            case "getById":
                if (responseData != null) {
             
                    this.onUpdateState({
                        detailData: responseData
                    })
                }
                this.onUpdateState({ setIsLoad: false });
                break;
        }
    }

}