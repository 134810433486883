import React from 'react'
import BaseComponent from '../../shared/baseComponent'
import { Link, Navigate } from "react-router-dom";
import SupplierComparisonServices from '../../services/domainServices/supplierComparisonServices';
import { encryptionData } from '../../utils/Encryption';
import Table from '../../components/table/Table';
import { Bars } from 'react-loader-spinner';

export default class SupplierComparisonList extends BaseComponent {
  constructor(props) {
    super(props);
    this.supplierServices = new SupplierComparisonServices();
    this.state = {
      pagination: {
        ...this.supplierServices.pagination,
        openStatus:props.openStatus,
        
      },
      getList: [],
      setIsLoad: false
    };
    this.supplierServices.onUpdateState = this.updateState.bind(this);
    this.supplierServices.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    this.supplierServices.onGetSupplierList(this.state.pagination);
  }

  onActionCall = (type, data) => {
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);
    switch (type) {
      case "View":
        window.open(`/SupplierComparisonDetails/id?=${encryptKey}`, '_blank');
        break;
      default:
        // Handle default case here
        break;
    }
  };
  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <div>
        <div className="top-search-add-button-sec mb-3 mt-0">
          <div className="row left-searchbar-sec"></div>
          <div className="right-button-sec d-flex align-items-center">
            <Link
              to="/AddSupplierComparison"
              className="tab-title btn-hover color-3 active"
            >
              Add Supplier<span>+</span>
            </Link>
          </div>
        </div> 
        <div className="Supplier-Comparison-List">
            {this.state.setIsLoad ? (
                <Bars
                   className="row"
                    wrapperStyle={{ marginTop: "150px" }}
                    color="#3c4b64"
                    height={55}
                    width="100%"
                    visible={this.state.setIsLoad}
                 />
                  ) : (   
                          <Table
                              columns={this.supplierServices.gridColmns}
                              rows={this.state.getList}
                              sortingColumns={[]}
                              pagination={this.state.pagination}
                              totalCount={this.state.TotalCount}
                              totalResultes={this.state.TotalCount}
                              setPagination={this.supplierServices.onPageChange.bind(this)}
                              customScope={[
                                {
                                  column: "supplierComparisonId",
                                  renderTableData:
                                    this.supplierServices.gridActionsViewCol.bind(this),
                                },
                              ]} />
                        )}
                        </div>
                      
          </div>
      
    )
  }
}
