import { IsProdMode, IsTestMode } from '../../utils/AppSetting';


const liveURL = process.env.REACT_APP_LIVEURL
const testURL = process.env.REACT_APP_TESTURL
const localHost = process.env.REACT_APP_LOCALURL
const lievFileManager = process.env.REACT_APP_FILEMANAGERLIVE
const testFileManager = process.env.REACT_APP_FILEMANAGERTEST

//** SSO Config */

// const ssoLiveURL = process.env.REACT_APP__SSOLIVEURLS
// const ssoTestURL = process.env.REACT_APP_SSOTESTURL
const ssoLiveURL = process.env.REACT_APP__SSOLIVEURL;
const ssoTestURL = process.env.REACT_APP_SSOTESTURL;
const ssolocalHost = process.env.REACT_APP_SSOLOCALURL;

// Web API url
export const APIURL = (IsProdMode) ? liveURL : (IsTestMode) ? testURL : localHost;
export const DownloadInputFile = (IsProdMode) ? lievFileManager : (IsTestMode) ? testFileManager : testFileManager;
export const SSOAPIURL = (IsProdMode) ? ssoLiveURL : (IsTestMode) ? ssoTestURL : ssolocalHost;


//Authentication services
export const AdminLogin = "Authentication/AdminLogin";
export const ValidateToken = "Authentication/ValidateToken";
export const ProjectAuthentication = "Account/ProjectAuthentication";
export const GetUserDetailsByClientIdAndSecretkey = "Authentication/GetUserDetailsByClientIdAndSecretkey";

//Manage Users
export const GetAllOperationalModuleListActiveInactive = "OperationalModule/GetAllOperationalModuleListActiveInactive";
export const GetAllOperationalModuleList = "OperationalModule/GetAllOperationalModuleList";
export const UpdateOptaionalModule = "OperationalModule/UpdateOptaionalModule";
export const AddOperationalModule = "OperationalModule/AddOperationalModule";
export const GetAllRunningRequestList = "OperationalModule/GetAllRunningRequestList";
export const GetAllRequestList = "OperationalModule/GetAllRequestList";
export const GetAllRequestHistoryList = "OperationalModule/GetAllRequestHistoryList";
export const DownloadScriptFile = "OperationalModule/DownloadScriptFile?VersionId={0}";


// export const GetMiddlewareRequestById = "Middleware/GetMiddlewareRequestById?id={0}";
export const GetMiddlewareRequestById = "Middleware/GetMiddlewareRequestById?id={0}&rpstageId={1}";
export const GetPartialPriceOutput = "Middleware/GetPartialPriceOutput?id={0}";
export const GetAllUserList = "User/GetAllUserList";
export const GetModuleStatusById = "ModuleType/GetModuleStatusById?id={0}";
export const GetAllServerList = "OperationalModule/GetAllServerList";
export const GetAllModuleRequestProcessType = "OperationalModule/GetAllModuleRequestProcessType";
export const GetAllOperationalModuleListById = "OperationalModule/GetAllOperationalModuleListById?id={0}";
export const GetMiddlewareRequestListById = "Middleware/GetMiddlewareRequestListById?id={0}";
export const GetMiddlewareDataUPloadFileById = "Middleware/GetMiddlewareDataUploadFileById?id={0}";
export const GetRequestDetailByMiddlewareId = "Middleware/GetRequestDetailByMiddlewareId?id={0}";
export const GetAllFileCountsByMiddlewareId = "Middleware/GetAllFileCountsByMiddlewareId?id={0}";
export const UpdateRequestStatusOnHold = "Middleware/UpdateRequestStatusOnHold?id={0}";




export const GetAllServerConfigurationsList = "ServerConfigurations/GetAllServerConfigurationsList";
export const AddServerConfigurations = "ServerConfigurations/AddServerConfigurations";
export const GetServerConfigurationsById = "ServerConfigurations/GetServerConfigurationsById?id={0}";
export const UpdateServerConfigurations = "ServerConfigurations/UpdateServerConfigurations";
export const GetAllAssignServerByModuleId = "ServerConfigurations/GetAllAssignServerByModuleId?id={0}";
export const GetAllUnAssignServerByModuleId = "ServerConfigurations/GetAllUnAssignServerByModuleId?id={0}";
export const AssignModuleToServer = "ServerConfigurations/AssignModuleToServer?id={0}";
export const GetAllActiveServerConfigurationsList = "ServerConfigurations/GetAllActiveServerConfigurationsList";
export const GetAllActiveServerList = "Server/GetAllActiveServerList";
export const DeleteServer = "ServerConfigurations/DeleteServer";
export const GetAllSupplierNameList = "OperationalModule/GetAllSupplierName";
export const GetAllCurrencies = "OperationalModule/GetAllCurrencies";



//**  Servers End Points */
export const AddServer = "Server/AddServer";
export const GetAllServerListing = "Server/GetAllServerListing";
export const GetServerById = "Server/GetServerById?id={0}";
export const UpdateServer = "Server/UpdateServer";
export const DeleteServerById = "Server/DeleteServerById";


// export const UpdateUserpassword = "User/UpdateUserpassword";

// import File
export const AddImport = "Import/AddImport";


//oprationtype module
export const AddModuleType = "ModuleType/AddModuleType";
export const GetAllModuleType = "ModuleType/GetAllModuleType";
export const GetLatestRequestById = "OperationalModule/GetLatestRequestById?id={0}";
export const UpdateModuleType = "ModuleType/UpdateModuleType";
export const GetOperationalModuleDetailsById = "OperationalModule/GetOperationalModuleDetailsById?id={0}";
export const DeleteModuleType = "ModuleType/DeleteModuleType";
// export const GetAllScriptList = "CodeManagement/GetAllScriptList";


//Manage Request
export const AddMiddlewareRequest = "Middleware/AddMiddlewareRequest";
export const GetAllRequestStatusById = "Middleware/GetAllRequestStatusById?id={0}";
export const UpdateRequestStatusAndEndTime = "Middleware/UpdateRequestStatusAndEndTime?id={0}";
export const GetRequestDetailsByDateFilterList = "Middleware/GetRequestDetailsByDateFilterList";

export const GetDashboardCounts = "Middleware/GetDashboardCounts";
export const GetAllDashboardRunningRequest = "Middleware/GetAllDashboardRunningRequest";
export const GetOutputFile = "Middleware/GetOutputFile?id={0}";

// User
export const UpdateUserDetails = "User/UpdateUserDetails";
export const UpdateUserpassword = "User/UpdateUserpassword";

export const GetUsersByUserName = "User/GetUsersByUserName";
export const AddUsers = "User/AddUsers";


export const GetUserDetailsByUserId = "User/GetUserDetailsByUserId?id={0}";
//Log 
export const GetAllLogsList = "Middleware/GetAllLogsList";
export const GetAllLogsById = "Logs/GetLogsById?id={0}&keyTypeName={1}";
export const AddLogs = "Middleware/AddLogs";
export const GetAllLogsDetails = "Middleware/GetLogDetails";
export const GetRunningRequestDetails = "Middleware/GetRunningRequestLogDetails";
export const GetAllLogsByLastId = "Middleware/GetAllLogsByMiddlewareLastId?id={0}";

// Data Analyzer 
export const AddDataAnalyzer = "DataAnalyzer/AddDataAnalyzer";
export const GetAllDataAnalyzer = "DataAnalyzer/GetAllDataAnalyzer";
export const GetDataAnalyzerById = "DataAnalyzer/GetDataAnalyzerById?id={0}";

// Code Management 
export const AddCodeManagement = "CodeManagement/AddCodeManagement";
export const UpdateScript = "CodeManagement/UpdateCodemanagement";
export const GetAllCodeManagementList = "CodeManagement/GetAllCodeManagementList";
export const GetCodeManagementById = "CodeManagement/GetCodeManagementById?id={0}";
export const GetAllVersionByCodeManagementId = "CodeManagement/GetAllVersionByCodeManagementId";
export const AddVersion = "CodeManagement/AddVersion";
export const GetAllScriptList = "CodeManagement/GetAllScriptList?id={0}";
export const DeleteScriptVersion = "CodeManagement/DeleteScriptVersion";
export const DeleteCodeManagementScript = "CodeManagement/DeleteCodeManagementScript";
export const UpdateAddVersionIsActive = "CodeManagement/UpdateAddVersionIsActive";

// Code Management
export const UploadScriptFile = "CodeManagement/UploadScriptFile";



//Product Data Sync

export const AddProductDataSync = "ProductDataSync/AddProductDataSync";
export const AddProductDataAutoSync = "ProductDataSync/AddProductDataAutoSync";
export const GetAllProductDataSync = "ProductDataSync/GetAllProductDataSync";
export const GetProductDataSyncById = "ProductDataSync/GetProductDataSyncById?id={0}";
export const GetLogsById = "Logs/GetLogsById?id={0}&keyTypeName={1}";
export const GetAllProductDataSyncImportType = "ProductDataSync/GetAllProductDataSyncImportType";
export const GetProductDataSyncOutputFileById = "ProductDataSync/GetProductDataSyncOutputFileById?id={0}";
export const DeleteProductDataSyncByProductDataSyncId="ProductDataSync/DeleteProductDataSyncByProductDataSyncId?id={1}";
export const ExportProductSyncAnalyzer="ProductDataSync/ExportProductSyncAnalyzer";

//Product Import

export const AddProductImport = "ProductImport/AddProductImport";
export const GetAllProductImport = "ProductImport/GetAllProductImport";
export const ExportChemindexAnalyzer = "ProductImport/ExportChemindexAnalyzer";
export const GetProductImportById = "ProductImport/GetProductImportById?id={0}";
export const GetProductImportOutputFileById = "ProductImport/GetProductImportOutputFileById?id={0}";
export const GetProductDataSyncListByProductId = "ProductImport/GetProductDataSyncListByProductId?id={0}";
export const GetAllStatus="ProductImport/GetAllStatus";
export const DeleteProductImportByProductImportId="ProductImport/DeleteProductImportByProductImportId";

//Link Builder

export const AddLinkBuilder = "LinkBuilder/AddLinkBuilder";
// export const AddLinkBuilderAutoSync = "LinkBuilder/AddLinkBuilderAutoSync";
export const AddMiddlewareGroupRequest = "RequestGroup/AddMiddlewareGroupRequest";
export const GetLinkBuilderList = "LinkBuilder/GetLinkBuilderList";
export const GetModuleTypeForLinkBuilder = "LinkBuilder/GetModuleTypeForLinkBuilder";
export const GetLinkBuilderById = "LinkBuilder/GetLinkBuilderById?id={0}";
export const GetLinkBuilderOutputFileById = "LinkBuilder/GetLinkBuilderOutputFileById?id={0}";

//** Regulation */
export const AddRegulation = "Regulation/AddRegulation";
export const UpdateRegulation = "Regulation/UpdateRegulation";
export const GetAllRegulation = "Regulation/GetAllRegulation";
export const DeleteRegulation = "Regulation/DeleteRegulation";
export const GetRegulationById = "Regulation/GetRegulationById?id={0}";
export const AddRegulationRequest = "Regulation/AddRegulationRequest";
export const GetAllRegulationRequest = "Regulation/GetAllRegulationRequest";
export const GetAllRegulationNameList = "Regulation/GetAllRegulationNameList";
export const GetRegulationRequestById = "Regulation/GetRegulationRequestById?id={0}";
export const GetRegulationOutputFileById = "Regulation/GetRegulationOutputFileById?id={0}";
export const ExportRegulationDetails = "Regulation/ExportRegulationDetails?regulationID={0}";


//EmailAddress
export const AddEmailValidation = "EmailValidation/AddEmailValidation";
export const GetEmailValidationList = "EmailValidation/GetEmailValidationList";
export const GetEmailValidationById = "EmailValidation/GetEmailValidationById?id={0}";
export const GetEmailValidationOutputFileById = "EmailValidation/GetEmailValidationOutputFileById?id={0}";


//File Manager
export const GetAllModuleNameList = "FileManager/GetAllModuleNameList";
export const GetModuleFileNameList = "FileManager/GetModuleFileNameList";
export const DeleteFileByModuleType = "FileManager/DeleteFileByModuleType";

//** Linkup Request */
export const AddLinkupRequest = "Linkup/AddLinkupRequest";
export const GetAllLinkupRequest = "Linkup/GetAllLinkupRequest";
export const GetLinkupRequestById = "Linkup/GetLinkupRequestById?id={0}";
export const GetLinkupRequestOutputFileById = "Linkup/GetLinkupRequestOutputFileById?id={0}";

//Exception Services
export const GetErrorTraceList = "ErrorTrace/GetErrorTraceList";
export const GetErrorTraceById = "ErrorTrace/GetErrorTraceById?errorLogId={0}";

//Request Group
export const GetRequestGroupList = "RequestGroup/GetRequestGroupList";
export const GetMiddlewareGroupRequestList = "RequestGroup/GetMiddlewareGroupRequestList";
export const GetMiddlewareGroupRequestById = "RequestGroup/GetMiddlewareGroupRequestById?id={0}";

// Bingo Product Import 
export const GetApplicationsList = "BingoProductImport/GetApplicationsList";
export const AddProductDataImport = "BingoProductImport/AddProductDataImport";
export const ProductDataImportList = "BingoProductImport/ProductDataImportList";
export const GetProductDataImportById = "BingoProductImport/GetProductDataImportById?id={0}";

//** Regulation */
export const AddSupplierComparison = "SupplierComparison/AddSupplierComparison";
export const GetSupplierComparisonList = "SupplierComparison/GetSupplierComparisonList";
export const GetSupplierComparisonById = "SupplierComparison/GetSupplierComparisonById?id={0}";

// CehmIndex DashBoard

export const GetChemIndexDashboardCounts = "ChemIndexDashBoard/GetChemIndexDashBoardCounts";
// Image Management
 
export const GetAllImageGenerationRequestType="ImageManagement/GetAllImageGenerationRequestType";
export const AddImageGenerationRequests="ImageManagement/AddImageGenerationRequests";
export const GetAllImageGenerationRequestsList="ImageManagement/GetAllImageGenerationRequestsList";
export const GetImageGenerationRequestsByImageGenerationRequestID="ImageManagement/GetImageGenerationRequestsByImageGenerationRequestID?id={0}";
