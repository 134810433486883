//** Component  */
import React from "react";
import ManageLinkBuilderList from "./ManageLinkBuilder";
import RequestOperationalModuleListTab from "../../styles/RequestOperationalModuleListTab";

//** Lib */


 

const data = [
  {
    id: "1",
    tabTitle: "Open",
    tabContent: < ManageLinkBuilderList  openStatus={1} />,
  },
  {
    id: "2",
    tabTitle: "Archive",
    tabContent: < ManageLinkBuilderList openStatus={2} />,
  },
];
function LinkBuilderList() {
  const [visibleTab, setVisibleTab] = React.useState(data[0].id);

  const listTitles = data.map((item) => (
    <li
      onClick={() => setVisibleTab(item.id)}
      className={
        visibleTab === item.id
          ? "tab-title tab-title--active  btn-hover-1 color-3 active "
          : "btn-hover-1 color-3 tab-title"
      }
    >
      {item.tabTitle}
    </li>
  ));

  const listContent = data.map((item) =>
    visibleTab === item.id ? <p style={{}}>{item.tabContent}</p> : null
  );
  return (
    <>
      <RequestOperationalModuleListTab />
      <div>
        <div className="tabs new-btn-width scroll_content_logs">
          <ul className="tabs-titles justify-content-left new-border pl-0">
            {listTitles}
          </ul>
          <div className="tab-content mt-2">{listContent}</div>
        </div>
      </div>
    </>
  );
}

export default LinkBuilderList;
