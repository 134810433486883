import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";

//** Services */
import productImportDomainServices from "../../services/domainServices/productImportServices";
//*** Lib */
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { encryptionData } from '../../../src/utils/Encryption';
import ProductImportServices from "../../services/axiosServices/apiServices/ProductImportServices";
import moment from "moment";
import { saveAs } from 'file-saver';
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";

export default class ProductImport extends BaseComponent {
  constructor(props) {
    super(props);
    this.productImportServices = new productImportDomainServices();
    this.state = {
      pagination: {
        ...this.productImportServices.pagination,
        openStatus: props.openStatus
      },
      getList: [],
      isLoad: false,
      operationalFormField: this.productImportServices.dataItem,
      setIsLoad: false,
      setModulelists: [],

    };
    this.productImportServices.onUpdateState = this.updateState.bind(this);
    this.productImportServices.onActionCall = this.onActionCall.bind(this);
  }

  componentDidMount() {
    let detail = this.state.pagination;
    detail.statusId = 0;
    this.productImportServices.onGetAllProductImport(detail);
    this.productImportServices.onGetAllStatus();
  }

  exportReport = new ProductImportServices();

  onActionCall = (type, data) => {
    // *** getByID encryption ***//
    const encryptKey = encryptionData(data, 1);
    switch (type) {
      case "View":
        window.open(`/ProductImportDetail/id?=${encryptKey}`, "_blank");
        break;
      case "Delete":
        this.productImportServices.apiService.serviceManager.swalServices
          .Confirm("Are you sure, you want delete?", "", "Yes", "No")
          .then(async (confirm) => {
            if (confirm) {
              let request = {
                productImportId: data,
                deletedBy: 1,
              };
              this.productImportServices.onDelete(request,  this.state.pagination);
            }
          });
        break;
      default:
        // Handle default case here
        break;
    }
  };

  ExportReport = () => {

    this.setState({ isLoad: true })
    this.exportReport.exportChemindexAnalyzer().then((res) => {
      var formattedDate = moment(new Date()).format("YYMMDD")
      var filename = 'Export report' + formattedDate;
      var blob = new Blob([res.data])
      saveAs(blob, filename + ".xlsx");
    })
    this.setState({ isLoad: false })
  }


  handleChange(name, value) {
    let detail = this.state.pagination;
    detail[name] = value;
    if (name === "statusId") {
      this.productImportServices.onGetAllProductImport(detail);
    }
    if (name === "searchText") {
      detail[name] = value.trim();
      let data = { ...this.state.pagination };
      data.pageNo = 1;

      this.productImportServices.onGetAllProductImport(data);
      this.setState({ pagination: { ...data } });
    }
    this.setState({ operationalFormField: { ...detail } });
  }

  render() {
    return (
      <div>
        <div className="top-search-add-button-sec mb-3 mt-0">
          <div className="row left-searchbar-sec">
            {this.productImportServices.formFieldsSearch.map((Item, index) => {
              return (
                <>
                  {Item.fieldName === "statusId" ||
                    Item.fieldName === "searchText" ? (
                    <FormFieldGroup
                      className="col-md-6"
                      column={Item}
                      value={this.state.operationalFormField[Item.fieldName]}
                      options={Item.Options ? Item.Options : this.state.setModulelists}
                      onChange={(event) =>
                        this.handleChange(Item.fieldName, event.target.value)
                      }
                      key={index}
                    />
                  ) : null}
                </>
              );
            })}
          </div>
          <div className="right-button-sec d-flex align-items-center">
            <button
              className="tab-title mt-0 btn-hover color-3 active mr-0 pro-import-btn"
              onClick={() => this.ExportReport()}
            >{this.state.isLoad ? <ButtonLoader /> : "Export Report"}
              <i className="fas fa-file ml-2"></i>
            </button>
            <Link
              to="/AddProductImport"
              className="tab-title btn-hover color-3 active"
            >
              Add Product Import<span>+</span>
            </Link>
          </div>
        </div>
        <div className="product-import-list">
          {this.state.setIsLoad ? (
            <Bars
              className="row"
              wrapperStyle={{ marginTop: "150px" }}
              color="#3c4b64"
              height={55}
              width="100%"
              visible={this.state.setIsLoad}
            />
          ) : (
            <Table
              columns={this.productImportServices.gridColmns}
              rows={this.state.getList}
              sortingColumns={[]}
              pagination={this.state.pagination}
              totalCount={this.state.TotalCount}
              totalResultes={this.state.TotalCount}
              setPagination={this.productImportServices.onPageChange.bind(this)}
              customScope={[
                {
                  column: "productImportId",
                  isDeleteRequired: true,
                  renderTableData:
                    this.productImportServices.gridSyncDataActionsCol.bind(this),
                },
              ]}
            />
          )}
        </div>
      </div>
    );
  }
}
