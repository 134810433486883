import { ErrorMessage } from '../utils/Messages';
import SererviceManager from './serviceManager';
import LogServices from "../services/axiosServices/apiServices/LogServices";
import UserServices from '../services/axiosServices/apiServices/UserServices';
import ManageSupplierServices from '../services/axiosServices/apiServices/ManageSupplierServices';
import AuthenticationServices from '../services/axiosServices/apiServices/AuthenticationServices';
import ManageOperationalServices from '../services/axiosServices/apiServices/ManageOperationalServices';
import MiddlewareRequestServices from '../services/axiosServices/apiServices/MiddlewareRequestServices';
import OperationalModuleServices from '../services/axiosServices/apiServices/OperationalModuleTypeServices';
import ServerConfigurationsServices from '../services/axiosServices/apiServices/ServerConfigurationsServices';
import DatabaseAnalyzerServices from '../services/axiosServices/apiServices/DatabaseAnalyzerServices';
import SupplierComparisonServices from '../services/axiosServices/apiServices/SupplierComparisonServices';
import CodeManagementService from '../services/axiosServices/apiServices/CodeManagementService';
import ServersServices from '../services/axiosServices/apiServices/ServersServices';
import ProductDataSyncServices from '../services/axiosServices/apiServices/ProductDataSyncServices';
import ProductImportServices from '../services/axiosServices/apiServices/ProductImportServices';
import LinkBuilderServices from '../services/axiosServices/apiServices/LinkBuilderServices';
import RegulationService from '../services/axiosServices/apiServices/RegulationService';
import EmailValidationServices from '../services/axiosServices/apiServices/EmailValidationServices';
import FileManagerServices from '../services/axiosServices/apiServices/FileManagerServices';
import LinkupServices from '../services/axiosServices/apiServices/LinkupServices';
import ExceptionServices from '../services/axiosServices/apiServices/ExceptionServices';
import RPARequestGroupServices from '../services/axiosServices/apiServices/RPARequestGroupServices';
import BingoProductImportService from '../services/axiosServices/apiServices/BingoProductImportService';
import ChemIndexDashBoardCountsServices from '../services/axiosServices/apiServices/ChemIndexDashBoardCounts';
import ImageManagementServices from '../services/axiosServices/apiServices/ImageManagementServices';

//**
export default class APISererviceManager {

  logServices = new LogServices();
  userServices = new UserServices();
  serviceManager = new SererviceManager();
  authServices = new AuthenticationServices();
  middlewareRequest = new MiddlewareRequestServices();
  manageSupplierServices = new ManageSupplierServices();
  manageOperationalServices = new ManageOperationalServices();
  rpaRequestGroupServices = new RPARequestGroupServices();
  operationalModuleServices = new OperationalModuleServices();
  serverConfigurationsServices = new ServerConfigurationsServices();
  databaseAnalyzerServices = new DatabaseAnalyzerServices();
  supplierComparisonServices = new SupplierComparisonServices();
  codeManagementService = new CodeManagementService();
  serversServices = new ServersServices();
  productDataSyncServices = new ProductDataSyncServices();
  productImportServices = new ProductImportServices();
  linkBuilderServices = new LinkBuilderServices();
  regulationService = new RegulationService();
  emailValidationServices = new EmailValidationServices();
  fileManagerServices = new FileManagerServices();
  linkupServices = new LinkupServices();
  exceptionServices = new ExceptionServices();
  bingoProductImportServices = new BingoProductImportService();
  chemIndexDashBoardServices= new ChemIndexDashBoardCountsServices();
  imageManagementServices=new ImageManagementServices();



  onAPICall(key, apiFun, data, onCallBack, onUpdateState, getRequest) {
   
    onUpdateState({ "setIsLoad": true, "loading": false });
    apiFun(data).then((response) => {
     
      if (response.statusCode === 200 && response.responseItem != null) {
        onCallBack(key, true, response.responseItem.responseContent, getRequest);

      } else {
       
        onCallBack(key, false, {});
        if (key === 'login') {
          this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.InvalidAuthorization);
        }
        else {
          this.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
        }
      }
    });
  }

}
