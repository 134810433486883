import { AddImageGenerationRequests, GetAllImageGenerationRequestType, GetAllImageGenerationRequestsList, GetImageGenerationRequestsByImageGenerationRequestID } from "../ApiEndPoints";
import { axiosGet, axiosPost } from "../AxiosRequests";

export default class ImageManagementServices {

    async addImageGenerationRequests(request) {
  
        return axiosPost(AddImageGenerationRequests, request);
    }
    async getAllImageGenerationRequestsList(request) {
      
        return axiosPost(GetAllImageGenerationRequestsList, request);
    }

    async getAllImageGenerationRequestType(request) {
        return axiosGet(GetAllImageGenerationRequestType, request);
    }

    async getImageGenerationRequestsByImageGenerationRequestID(request) {
  
        return axiosGet(GetImageGenerationRequestsByImageGenerationRequestID, request);
    }
}