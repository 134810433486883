import React from "react";

//** Lib's */
import BaseComponent from "../../shared/baseComponent";
import { decryptionData } from "../../utils/Encryption";

//** Service's */
import ImageManagementDomainServices from "../../services/domainServices/ImageManagementDomainServices";

export default class ImageManagementDetail extends BaseComponent {

    //** Constructor  and Component method */
    constructor(props) {
        super(props);
        this.imageDomainServices = new ImageManagementDomainServices();
        this.state = {
            detailData: {}
        }
        this.imageDomainServices.onUpdateState = this.updateState.bind(this);
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
       
        let id = params.get("");
        const decryptId = decryptionData(id, false);
      
        if (decryptId > 0) {
         
            this.imageDomainServices.onGetById(decryptId);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="card mb-5">
                    <div className="card-title-main px-3 py-2">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h5 className="titletexts font-size-16">Image Generation Information </h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-main-width">
                    <div className="card-body custom-card-body">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Request Name </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.imageGenerationRequestName}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Request Type</span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.imageGenerationRequestTypeName}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Status </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16 ">{this.state.detailData.statusName}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Total Images Before Process </span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.totalImagesBeforeProcess ? this.state.detailData.totalImagesBeforeProcess : "NA"}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">Total Images After Process</span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.totalImagesAfterProcess ? this.state.detailData.totalImagesAfterProcess : "NA"}</span>
                                </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="inner-detail-sec">
                                    <span className="detail-title">New Image Count</span>
                                    &nbsp;&nbsp;&nbsp; <span className="font-size-16">{this.state.detailData.newImageCount ? this.state.detailData.newImageCount : "NA"}</span>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    </div>
                </div>

                
            </React.Fragment>
        )
    }
}