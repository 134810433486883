import BaseDomainService from './baseDomainServices';
import { SuccessMessage } from "../../utils/Messages";
import { getValidationRuleList } from "../../utils/validation/generateValidationRule";
import { DataFormat } from '../../utils/DateFormat';

const operationalFormField = {
    Name: "",
    FileName: "",
    File: "",
    CreatedBy: 1,
    TutomerImport: true,
    ChemPropertiesImport: true,
    PubChemSync: true,
    ProductUpdate : false,
    Regualtion : true
    
}

let onInitAddVersionDetails = {
    //  active: true
    TutomerImport: true,
    ChemPropertiesImport: true,
    PubChemSync: true,
    ProductUpdate :  false,
    Regulation : true,
}

export default class productImportServices extends BaseDomainService {

    add = this.apiService.productImportServices.addProductImport;
    getById = this.apiService.productImportServices.getProductImportById;
    getAllProductImport = this.apiService.productImportServices.getAllProductImport;

    getLogsByProductDataId = this.apiService.productImportServices.getLogsById;
    getProductDataOutputFileById = this.apiService.productImportServices.getProductImportOutputFileById;
    getProductDataSyncListByProductId = this.apiService.productImportServices.getProductDataSyncListByProductId;
    getAllStatus = this.apiService.productImportServices.getAllStatus;
    deleteProductImportByProductImportId = this.apiService.productImportServices.deleteProductImportByProductImportId;

    dataItem;

    // define the form fields
    formFields = [
        {
            inputType: "text",
            tittle: "Request Name",
            placeholder: "Request Name",
            fieldName: "Name",
            className: 'col-md-6',
            maxLength: 200,
            validation: [{ type: "require" }]
        },
        {
            inputType: "file",
            tittle: "Input File",
            placeholder: "Input File",
            fieldName: "File",
            subRequest: "1",
            className: 'col-md-6 PartielFile',
            validation: [{ type: "require" }]
        },
        {
            inputType: "checkbox",
            tittle: "TutomerImport",
            fieldName: "TutomerImport",
            checked: true,
            className: 'col-md-3 mt-3',
            // validation: [{ type: "require" }]
        },
        {
            inputType: "checkbox",
            tittle: "ChemPropertiesImport",
            fieldName: "ChemPropertiesImport",
            checked: true,
            className: 'col-md-3 mt-3'
        },
        {
            inputType: "checkbox",
            tittle: "PubChemSync",
            fieldName: "PubChemSync",
            checked: true,
            className: 'col-md-3 mt-3'
        },
        {
            inputType: "checkbox",
            tittle: "ProductUpdate",
            fieldName: "ProductUpdate",
            checked: false,
            className: 'col-md-2 mt-3'
        },
        {
            inputType: "checkbox",
            tittle: "Regualtion",
            fieldName: "Regualtion",
            checked: false,
            className: 'col-md-2 mt-3'
        }
    ]
    formFieldsSearch = [
        {
            inputType: "text",
            tittle: "",
            placeholder: "Search ...",
            fieldName: "searchText",
            mode: 1,
            className: 'col-md-4 mb-0'
        },
        {
            inputType: "select",
            tittle: "",
            placeholder: "Select Dropdown",
            fieldName: "statusId",
            options: [],
            optionFieldName: "statusName",
            subRequest: "1",
            className: 'col-md-6',
        },
    ]
    gridColmns = [
        { name: 'Name', title: 'Request Name' },
        { name: 'statusName', title: 'Status' },
        { name:'startDate',title :'Start Date' },
        { name:'endDate',title :'End Date'  },
        { name:'processTime',title :'Process Time'  },
        { name: 'productImportId', title: 'Action' },
    ];
    addScriptDetails;

    constructor() {
        super();
        this.actionKey = "productImportId";
        this.dataItem = { ...operationalFormField };
        this.onPaginationUpdate = this.onGetAllProductImport.bind(this);
        this.addScriptDetails = { ...onInitAddVersionDetails };
    }
    addversionFormFields = [
        {
            inputType: "checkbox",
            tittle: "TutomerImport",
            fieldName: "TutomerImport",
            checked: false,
            partialrequest: true,
            className: 'col-md-4 mt-4'
        },
        {
            inputType: "checkbox",
            tittle: "ChemPropertiesImport",
            fieldName: "ChemPropertiesImport",
            checked: false,
            partialrequest: true,
            className: 'col-md-5 mt-4'
        },
        {
            inputType: "checkbox",
            tittle: "PubChemSync",
            fieldName: "PubChemSync",
            checked: false,
            partialrequest: true,
            className: 'col-md-4 mt-2'
        },
        {
            inputType: "checkbox",
            tittle: "ProductUpdate",
            fieldName: "ProductUpdate",
            checked: false,
            partialrequest: true,
            className: 'col-md-4 mt-2'
        },
        {
            inputType: "checkbox",
            tittle: "Regulation",
            fieldName: "Regulation",
            checked: false,
            partialrequest: true,
            className: 'col-md-4 mt-2'
        },

    ];


    productDataDetailForm = [
        {
            inputType: "label",
            tittle: "Request Name",
            fieldName: "name",
            className: 'col-md-10',
        },
        {
            inputType: "label",
            tittle: "Total Counts",
            fieldName: "totalCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Non Existing Counts",
            fieldName: "nonExistingCounts",
            className: 'col-md-4',

        },
        {
            inputType: "label",
            tittle: "Existing Data Counts",
            fieldName: "existingDataCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Association Counts",
            fieldName: "associationCounts",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Error Count",
            fieldName: "errorCount",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Status",
            fieldName: "statusName",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Created Date",
            fieldName: "createdDate",
            className: 'col-md-4',
        },
        {
            inputType: "label",
            tittle: "Error Message",
            fieldName: "error",
            className: 'col-md-12',
        },
    ]

    onInitService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.formFields)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }

    onAdd = (request) => {
        this.apiService.onAPICall("addProductImport", this.add, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllProductImport = (requst,pagination) => {
        this.apiService.onAPICall("getAllProductImport", this.getAllProductImport, requst, this.onAPIResponse, this.onUpdateState,pagination);
    }
    onDelete = (request,pagination) => {
        this.apiService.onAPICall("deleteProductImportByProductImportId", this.deleteProductImportByProductImportId, request, this.onAPIResponse, this.onUpdateState,pagination)
    };
    onGetById = (requst) => {
        this.apiService.onAPICall("onGetById", this.getById, requst, this.onAPIResponse, this.onUpdateState);
    }
    onGetLogsByProductDataId = (request) => {
        this.apiService.onAPICall("getLogsByProductDataId", this.getLogsByProductDataId, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetProductDataOutputFileById = (request) => {
        this.apiService.onAPICall("getProductDataOutputFileById", this.getProductDataOutputFileById, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetProductDataSyncListByProductId = (request) => {
        this.apiService.onAPICall("getProductDataSyncListByProductId", this.getProductDataSyncListByProductId, request, this.onAPIResponse, this.onUpdateState);
    }
    onGetAllStatus = (request) => {
        this.apiService.onAPICall("getAllStatus", this.getAllStatus, request, this.onAPIResponse, this.onUpdateState);
    }
    onResetVersionData = () => {
        this.dataItem = { ...onInitAddVersionDetails }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            addVersionDetails: this.dataItem,
            show: false,
            validState: resetValidation
        });
    }

    onRedirectoList = () => {
        this.onRedirect("/ProductImport");
    }

    onAPIResponse = (key, isSuccess, responseData,getRequest) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "addProductImport":
                let data = responseData;
                if (data.errorType === 'EXIST') {
                    this.apiService.serviceManager.swalServices.Error(SuccessMessage.Already_Exist.replace("{0}", data.errorColumn))
                } else {
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Add_Success.replace("{0}", "Product Import"));
                    this.onRedirectoList();
                }
                this.onUpdateState({ "setIsLoad": false }); 
                break;
            case "getAllProductImport":
                
            let itemList = responseData.itemList;
            if (responseData !== null) {
                itemList.forEach(function (i) {
                  i.startDate = i.startDate ? DataFormat(i.startDate) : "-";
                  i.endDate = i.endDate ? DataFormat(i.endDate) : "-";
                  i.processTime=i.processTime ? i.processTime : "-";
                  return { ...itemList}
                });
                this.onUpdateState({
                    "getList": itemList,
                    "setIsLoad": false,
                    "TotalCount": responseData.totalCount,
                });
            }
                break;
            case "onGetById":
          
                if (responseData != null) {
                    responseData.name = responseData.name ? responseData.name : "NA"
                    responseData.totalCounts = responseData.totalCounts ? responseData.totalCounts : "0"
                    responseData.nonExistingCounts = responseData.nonExistingCounts ? responseData.nonExistingCounts : "0"
                    responseData.existingDataCounts = responseData.existingDataCounts ? responseData.existingDataCounts : "0"
                    responseData.associationCounts = responseData.associationCounts ? responseData.associationCounts : "0"
                    responseData.invalidCounts = responseData.invalidCounts ? responseData.invalidCounts : "0"
                    responseData.statusName = responseData.statusName ? responseData.statusName : "NA"
                    responseData.error = responseData.error ? responseData.error : "NA"
                    responseData.createdDate = responseData.createdDate ? DataFormat(
                        responseData.createdDate
                      ) : "NA"

                     responseData.linkUpCounts = responseData.linkUpCounts ? responseData.linkUpCounts : "0"
                    this.onUpdateState({
                        productDataDetail: responseData
                    })

                }
                this.onUpdateState({
                    "setIsLoad": false
                });
                break;
            case "getLogsByProductDataId":
                if (responseData !== null) {
                    this.onUpdateState({
                        logsList: responseData
                    })
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
            case "getProductDataOutputFileById":
                if (responseData.length > 0) {
                    this.onUpdateState({
                        outputFileList: responseData
                    })
                }
                this.onUpdateState({
                    "setIsLoad": false,
                });
                break;
                case "getProductDataSyncListByProductId" :
                    if (responseData !== null) {
                        this.onUpdateState({
                            mapData: responseData
                        })
                    }
                    this.onUpdateState({
                        "setIsLoad": false,
                    });    
                break;

                case "getAllStatus":
                    let itemList1 = responseData;

                    
                    if (itemList1 !== null) {
                        const stutusType = itemList1.map(item => ({
                            id: item.statusId,
                            name: item.statusName,
                        }));
    
                        const statuslist = [
                            { id: 0, name: 'All' },
                            ...stutusType,
                        ];
    
                        this.onUpdateState({
                            setModuleList: stutusType,
                            setModulelists: statuslist,
                            "setIsLoad": false,
                        });
                    }
                    break;
                    case "deleteProductImportByProductImportId":
                        if (responseData !== null) {
                            this.apiService.serviceManager.swalServices.Success(SuccessMessage.Delete_Success.replace("{0}", "Record"));
                            this.onGetAllProductImport(getRequest);
                        }
                        this.onUpdateState({ "setIsLoad": false });
                        break;
                default:
                    // Handle default case here
                    break;
        }
    }

}
