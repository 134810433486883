import React from "react";
import { Bars } from "react-loader-spinner";
import { Link, Navigate } from "react-router-dom";

//** Lib's */
import Table from "../../components/table/Table";
import BaseComponent from "../../shared/baseComponent";
import { encryptionData } from "../../utils/Encryption";
import FormFieldGroup from "../../components/UI Element/FormGrop/FormFieldGroup";

//** Service's */
import BingoProductImportDomainService from "../../services/domainServices/BingoProductImportDomainService";

export default class BingoProductImportList extends BaseComponent {

    //** Constructor  and Component method */
    constructor(props) {
        super(props);
        this.domainServices = new BingoProductImportDomainService();
        this.state = {
            setIsLoad: false,
            dataList: [],
            pagination: {...this.domainServices.pagination,
                openStatus: props.openStatus},
            searchList: this.domainServices.dataItem
        };
        this.domainServices.onUpdateState = this.updateState.bind(this);
        this.domainServices.onActionCall = this.onActionCall.bind(this);
    }

    componentDidMount() {
        this.domainServices.onGetList(this.state.pagination);
    }

    //** Handle Changes */
    handleChange = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        if (name === "searchText") {
            detail[name] = value.trim();
            let request = { ...this.state.pagination };
            request.pageNo = 1;
            this.domainServices.onGetList(request);
            this.setState({ pagination: { ...request } });
        }
        this.setState({ searchList: { ...detail } });
    };

    handleChangeEvent = (name, value) => {
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ searchList: { ...detail } });
    };

    //** Action Method */
    onActionCall = (type, data) => {
        const encryptKey = encryptionData(data, 1);
        switch (type) {
            case "View":
                window.open(`/BingoProductImportDetail/id?=${encryptKey}`, "_blank");
                break;
            default:
                // Handle default case here
                break;
        }
    }

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <React.Fragment>
                <div className="top-search-add-button-sec mb-3">
                    <div className="row left-searchbar-sec">
                        {this.domainServices.searchformFields.map((Item, index) => {
                            return (
                                <FormFieldGroup
                                    className="ms-auto"
                                    column={Item}
                                    value={this.state.searchList[Item.fieldName]}
                                    onChange={(event) => this.handleChangeEvent(Item.fieldName, event.target.value)}
                                    onBlur={Item.onBlur ? Item.onBlur : null}
                                    onKeyPress={(e) => this.handleChange(Item.fieldName, e.target.value)}
                                    key={index}
                                />
                            )
                        })}
                    </div>
                    <div className="right-button-sec">
                        <Link to="/AddBingoProductImport" className="tab-title btn-hover color-3 active">
                            Add Product Import <span>+</span>
                        </Link>
                    </div>
                </div>
                <div className="code-managment-list">
                    {this.state.setIsLoad ? (
                        <Bars
                            className="row"
                            wrapperStyle={{ marginTop: "150px" }}
                            color="#3c4b64"
                            height={55}
                            width="100%"
                            visible={this.state.setIsLoad} />
                    ) : (
                        <Table
                            columns={this.domainServices.gridColmns}
                            rows={this.state.dataList}
                            sortingColumns={[]}
                            pagination={this.state.pagination}
                            totalCount={this.state.TotalCount}
                            totalResultes={this.state.TotalCount}
                            setPagination={this.domainServices.onPageChange.bind(this)}
                            customScope={[
                                {
                                    column: "dataImportId",
                                    renderTableData: this.domainServices.gridActionsViewCol.bind(this)
                                }
                            ]} />
                    )}
                </div>
            </React.Fragment>
        )
    }
}