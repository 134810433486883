//** Component  */
import React from "react";

//** Lib */
import RequestOperationalModuleListTab from "../../styles/RequestOperationalModuleListTab";
import ManageLinkupRequest from "./ManageLinkupRequest";

const data = [
  {
    id: "1",
    tabTitle: "Open",
    tabContent: < ManageLinkupRequest openStatus={1} />,
  },
  {
    id: "2",
    tabTitle: "Archive",
    tabContent: < ManageLinkupRequest openStatus={2} />,
  },
];
// console.log(data);
function LinkUpRequestList() {
    const [visibleTab, setVisibleTab] = React.useState(data[0].id);

    const listTitles = data.map((item) => (
     
      <li
        onClick={() => setVisibleTab(item.id)}
        className={
          visibleTab === item.id
            ? "tab-title tab-title--active  btn-hover-1 color-3 active "
            : "btn-hover-1 color-3 tab-title"
        }
      >
        {item.tabTitle}
      </li>
  
    ));
  
    const listContent = data.map((item) =>
      visibleTab === item.id ? <p style={{}}>{item.tabContent}</p> : null
    );   

  return (
    <>
      <RequestOperationalModuleListTab />
      <div>
        <div className="tabs new-btn-width scroll_content_logs">
          <ul className="tabs-titles justify-content-left new-border pl-0">
            {listTitles}
          </ul>
          <div className="tab-content mt-2">{listContent}</div>
        </div>
      </div>
    </>
  );
}

export default LinkUpRequestList;
