
import { GetChemIndexDashboardCounts } from '../ApiEndPoints';
import { axiosGet } from '../AxiosRequests';

export default class ChemIndexDashBoardCountsServices {

    // API function
    async getChemIndexDashboardCounts(request) {
  
        return axiosGet(GetChemIndexDashboardCounts, request)
    }
}