//** Component  */
import RequestInitiate from '../components/requestFlow/RequestInitiate';
import RequestScrapping from '../components/requestFlow/RequestScrapping';

//*** Pages */
import Dashboard from '../pages/dashboard/Dashboard';
import RPAModules from '../pages/manageoperational/RPAModules';
import SyncData from '../pages/manageProductDataList/SyncData';
import AddNewComponent from '../pages/manageoperational/AddNewComponent';
import RPARequest from '../pages/requestOPM/RPARequest';
import RPARequestGroup from '../pages/requestOPM/RPARequestGroup';
import RequestDetails from '../pages/requestOPM/RequestDetails';
import RPARequestGroupDetails from '../pages/requestOPM/RPARequestGroupDetails';
import GetConfiguration from '../pages/serverConfiguration/GetConfiguration';
import ManageUsers from '../pages/manageUsers/ManageUsers';
import AddNewUsers from "../pages/manageUsers/AddNewUsers"
import LogTable from '../pages/logTable/LogTable';
import PageNotFound from '../components/common/error/PageNotFound';
import ServerClientConfigurations from '../pages/serverConfiguration/ServerClientConfigurations';
import AddServerConfigurations from '../pages/serverConfiguration/AddServerConfigurations';
import AddRequest from '../pages/requestOPM/AddRequest';
import UsersProfile from '../pages/profile/UsersProfile';
import AddPartialRequest from '../pages/requestOPM/AddPartialRequest';
import UnAuthorized from '../pages/authentication/UnAuthorized';
import PriceValidation from '../pages/database Analyzer/PriceValidation';
import StockValidation from '../pages/database Analyzer/StockValidation';
import PriceComparison from '../pages/database Analyzer/PriceComparison';
import DataAnalyzerList from '../pages/database Analyzer/DataAnalyzerList';
import SupplierComparisonList from '../pages/database Analyzer/SupplierComparisonList';
import DatabaseAnalyzerDetails from '../pages/database Analyzer/DatabaseAnalyzerDetails';
import AddScript from '../pages/codeManagement/AddScript';
import CodeManagement from '../pages/codeManagement/CodeManagement';
import AddServer from '../pages/Server/AddServer';
import ManageServer from '../pages/Server/ManageServer';
import AddNewProductData from '../pages/manageProductDataList/AddNewProductData';
import AddProductImport from '../pages/manageProductImport/AddProductImport';
import AddSupplierComparison from '../pages/database Analyzer/AddSupplierComparison'
import ProductImport from '../pages/manageProductImport/ProductImport';
import ProductDataSyncDetail from '../pages/manageProductDataList/ProductDataSyncDetail';
import ProductImportDetail from '../pages/manageProductImport/ProductImportDetail';
import AddLinkBuilder from '../pages/manageLinkBuilder/AddLinkBuilder';
import ManageLinkBuilder from '../pages/manageLinkBuilder/ManageLinkBuilder';
import LinkBuilderDetail from '../pages/manageLinkBuilder/LinkBuilderDetail';

import AddRegulation from '../pages/regulations/manageRegulations/AddRegulation';
import ManageRegulation from '../pages/regulations/manageRegulations/ManageRegulation';
//** Regulation Request */
import AddRegulationRequest from '../pages/regulations/regulationRequest/AddRegulationRequest';
import ManageRegulationRequest from '../pages/regulations/regulationRequest/ManageRegulationRequest';
import RegulationRequestDetails from '../pages/regulations/regulationRequest/RegulationRequestDetails';

import AddEmail from '../pages/manageEmail/AddEmail';
import EmailValidation from '../pages/manageEmail/EmailValidation';
import EmailValidationDetail from '../pages/manageEmail/EmailValidationDetail';
import FileManager from '../pages/fileManager/FileManager';

//** Linkup Request */
import AddLinkupRequest from '../pages/linkup/AddLinkupRequest';
import LinkupRequestDetail from '../pages/linkup/LinkupRequestDetail';
import ManageLinkupRequest from '../pages/linkup/ManageLinkupRequest';
import Exception from '../pages/Exception/Exception';
import ExceptionDetails from '../pages/Exception/ExceptionDetails';
import SupplierComparisonDetails from '../pages/database Analyzer/SupplierComparisonDetails';

//** Bingo Product Import */
import AddBingoProductImport from '../pages/BingoProductImport/AddBingoProductImport';
import BingoProductImportList from '../pages/BingoProductImport/BingoProductImportList';
import BingoProductImportDetail from '../pages/BingoProductImport/BingoProductImportDetail';
import DataAnalyzer from '../pages/database Analyzer/DataAnalyzer';
import SupplierComparison from '../pages/database Analyzer/SupplierComparison';
import LinkUpRequestList from '../pages/linkup/LinkUpRequestList';
import SyncDataList from '../pages/manageProductDataList/progress-flow/SyncDataList';
import ProductImportList from '../pages/manageProductImport/ProductImportList';
import RPARequestGroupList from '../pages/requestOPM/RPARequestGroupList';
import LinkBuilderList from '../pages/manageLinkBuilder/LinkBuilderList';
import BingoProductImport from '../pages/BingoProductImport/BingoProductImport';
import ChemIndexDashBoard from '../pages/chemIndexDashBoard/ChemIndexDashBoard';
import ImageManagement from '../pages/imageManagement/ImageManagement';
import AddImageManagement from '../pages/imageManagement/AddImageManagement';
import ImageManagementDetail from '../pages/imageManagement/ImageManagementDetail';

const ComponentNavigation = [

    { path: 'Dashboard', title: 'Dashboard', component: Dashboard, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'RPAModules', title: 'RPA Modules', component: RPAModules, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddNewComponent', title: 'RPA Modules', component: AddNewComponent, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'RPARequest', title: 'RPA Request', component: RPARequest, hasParams: false, allowrole: ["admin", "DataUpload"] },
    //{ path: 'RPARequestGroup', title: 'RPA Request Group', component: RPARequestGroup, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'RPARequestGroup', title: 'RPA Request Group', component: RPARequestGroupList, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'ServerClientConfigurations', title: 'Server Client Configuration', component: ServerClientConfigurations, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddServerConfigurations', title: 'Add New Client', component: AddServerConfigurations, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'LogTable', title: 'Log Details', component: LogTable, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'RequestDetails/id', title: 'Request Details', component: RequestDetails, hasParams: true, allowrole: ["admin", "DataUpload"] },
    { path: 'RPARequestGroupDetails/id', title: 'Request Group Details', component: RPARequestGroupDetails, hasParams: true, allowrole: ["admin", "DataUpload"] },
    { path: 'GetConfiguration', title: 'Get Config', component: GetConfiguration, hasParams: true, allowrole: ["admin", "Developer"] },
    { path: 'ManageUsers', title: 'Manage Users', component: ManageUsers, hasParams: false, allowrole: ["admin"] },
    { path: 'AddNewUsers', title: 'Manage Users', component: AddNewUsers, hasParams: false, allowrole: ["admin"] },
    { path: 'AddNewUsers/id', title: 'Update Users Details', component: AddNewUsers, hasParams: true, allowrole: ["admin"] },
    { path: 'AddNewComponent/id', title: 'Update RPA Modules', component: AddNewComponent, hasParams: true, allowrole: ["admin", "Developer"] },
    { path: 'AddServerConfigurations/id', title: 'Update  Client', component: AddServerConfigurations, hasParams: true, allowrole: ["admin", "Developer"] },
    { path: 'RequestInitiate/id', title: 'Request Initiate ', component: RequestInitiate, hasParams: true, allowrole: ["admin", "DataUpload"] },
    { path: 'RequestScrapping/id', title: 'Request Scrapping ', component: RequestScrapping, hasParams: true, allowrole: ["admin", "DataUpload"] },
    { path: '404', title: '404 ', component: PageNotFound, hasParams: true, allowrole: ["admin", "DataUpload", "Developer"] },
    { path: 'AddRequest', title: 'RPA Request', component: AddRequest, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'UsersProfile', title: 'User Profile', component: UsersProfile, hasParams: false, allowrole: ["admin", "DataUpload", "Developer"] },
    { path: 'AddPartialRequest', title: 'RPA Request', component: AddPartialRequest, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: "UnAuthorized", title: 'UnAuthorized', component: UnAuthorized, hasParams: false, allowrole: ["admin", "DataUpload", "Developer"] },
    { path: 'PriceValidation', title: 'Price Validation', component: PriceValidation, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'StockValidation', title: 'Stock Validation', component: StockValidation, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'PriceComparison', title: 'Price Comparison', component: PriceComparison, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'DataAnalyzerList', title: 'Data Analyzer List', component: DataAnalyzer, hasParams: false, allowrole: ["admin", "DataUpload"] },
    //{ path: 'DataAnalyzerList', title: 'Data Analyzer List', component: DataAnalyzerList, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'SupplierComparisonList', title: 'Supplier Comparison List', component: SupplierComparison, hasParams: false, allowrole: ["admin", "DataUpload"] },
    //{ path: 'SupplierComparisonList', title: 'Supplier Comparison List', component: SupplierComparisonList, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'AddScript', title: 'Add Script', component: AddScript, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddScript/id', title: 'Update Script', component: AddScript, hasParams: true, allowrole: ["admin", "Developer"] },
    { path: 'CodeManagement', title: 'View Script', component: CodeManagement, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'AddServer', title: 'Add Server', component: AddServer, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'AddServer/id', title: 'Update Server', component: AddServer, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'ManageServer', title: 'View Server', component: ManageServer, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'DatabaseAnalyzerDetails/id', title: 'Database Analyzer Details', component: DatabaseAnalyzerDetails, hasParams: true, allowrole: ["admin", "DataUpload"] }, 
    { path: 'SupplierComparisonDetails/id', title: 'Supplier Comparison Details', component: SupplierComparisonDetails, hasParams: true, allowrole: ["admin", "DataUpload"] },
    { path: 'AddNewProductData', title: 'Product Data Sync', component: AddNewProductData, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddProductImport', title: 'Product Import', component: AddProductImport, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddSupplierComparison', title: 'Supplier Comparison', component: AddSupplierComparison, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddLinkBuilder', title: 'Link Builder', component: AddLinkBuilder, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'SyncData', title: 'Product Data Sync', component: SyncDataList, hasParams: false, allowrole: ["admin", "DataUpload"] },
    //{ path: 'SyncData', title: 'Product Data Sync', component: SyncData, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ProductImport', title: 'Product Import', component: ProductImportList, hasParams: false, allowrole: ["admin", "DataUpload"] },
   // { path: 'ProductImport', title: 'Product Import', component: ProductImport, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ManageLinkBuilder', title: 'Link Builder', component: LinkBuilderList, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ProductDataSyncDetail/id', title: 'Product Data Sync', component: ProductDataSyncDetail, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ProductImportDetail/id', title: 'Product Import', component: ProductImportDetail, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'LinkBuilderDetail/id', title: 'Link Builder', component: LinkBuilderDetail, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddRegulation', title: 'Regulations', component: AddRegulation, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddRegulation/id', title: 'Regulations', component: AddRegulation, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ManageRegulation', title: 'Regulations', component: ManageRegulation, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddRegulationRequest', title: 'Regulation Request', component: AddRegulationRequest, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddRegulationRequest/id', title: 'Regulation Request', component: AddRegulationRequest, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ManageRegulationRequest', title: 'Regulation Request', component: ManageRegulationRequest, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'RegulationRequestDetails/id', title: 'Regulation Detail', component: RegulationRequestDetails, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'AddEmail', title: 'Email Validation', component: AddEmail, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'EmailValidation', title: 'Email Validation', component: EmailValidation, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'EmailValidationDetail/id', title: 'Manage Email Validation', component: EmailValidationDetail, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'FileManager', title: 'File Manager', component: FileManager, hasParams: false, allowrole: ["admin", "Developer"] },
    //** Linkup Request */
    { path: 'AddLinkupRequest', title: 'Linkup Request', component: AddLinkupRequest, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ManageLinkupRequest', title: 'Linkup Request', component: LinkUpRequestList, hasParams: false, allowrole: ["admin", "DataUpload"] },
    //{ path: 'ManageLinkupRequest', title: 'Linkup Request', component: ManageLinkupRequest, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'LinkupRequestDetail/id', title: 'Linkup Detail', component: LinkupRequestDetail, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: "Exception", title: "Exception", component: Exception, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: "ExceptionDetails/id", title: "ExceptionDetails", component: ExceptionDetails, hasParams: false, allowrole: ["admin", "Developer"] },
    //** Bingo Product Import */
    { path: 'AddBingoProductImport', title: 'Product Import', component: AddBingoProductImport, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'BingoProductImportList', title: 'Product Import List', component: BingoProductImport, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'BingoProductImportDetail/id', title: 'Product Import List', component: BingoProductImportDetail, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ChemIndexDashBoard', title: 'ChemIndex DashBoard', component: ChemIndexDashBoard, hasParams: false, allowrole: ["admin", "DataUpload"] },
    { path: 'AddImageManagement', title: 'Image Management', component: AddImageManagement, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ImageManagementList', title: 'Image Management List', component: ImageManagement, hasParams: false, allowrole: ["admin", "Developer"] },
    { path: 'ImageManagementDetail/id', title: 'Image Management List', component: ImageManagementDetail, hasParams: false, allowrole: ["admin", "Developer"] },
];
export default ComponentNavigation;
