
//** Component  */
import React from 'react';
import BaseComponent from '../../shared/baseComponent';

import { Navigate } from 'react-router-dom';
import ChemIndexDashBoardServices from '../../services/domainServices/chemIndexDashBoardServices';

export default class ChemIndexDashBoard extends BaseComponent {
  constructor(props) {
    super(props)
    this.chemIndexDashBoardServices = new ChemIndexDashBoardServices();
    this.chemIndexDashBoardServices.onUpdateState = this.updateState.bind(this);
    this.state = {
     setIsLoad: false,
     dashBoardCountList: {},
   
    }
  }
  componentDidMount() {
    this.chemIndexDashBoardServices.onGetChemIndexDashboardCounts(this.state.pagination);  
   
  }

  render() {
    if (this.state.redirect != null) {
      return <Navigate to={this.state.redirect} />;
    }
    return (
      <>
        <nav id="layout-navbar">
        </nav>

        <div className="row">
          {this.chemIndexDashBoardServices.getAllChemIndexCardList.map((data) => (
            <div className='col-md-6 col-lg-3 col-xxl-3" mb-4'>
              <div className="DashBoard-card-New card-margin" style={{ paddingTop: '10px' }}>
                <div className="card-body name1 ">
                  <span className="fw-semibold d-block text-color mt-3"> {data.cardName} </span>
                  {data.cardName === "Total Chem Product" ? <><div className='card-count'>{this.state.dashBoardCountList.totalChemProduct ? this.state.dashBoardCountList.totalChemProduct : 0}</div></> : null}
                  {data.cardName === "Total Pubchem" ? <><div className='card-count'>{this.state.dashBoardCountList.totalPubchem ? this.state.dashBoardCountList.totalPubchem : 0}</div></> : null}
                  {data.cardName === "Total Chem Properties" ? <><div className='card-count'>{this.state.dashBoardCountList.totalChemProperties ? this.state.dashBoardCountList.totalChemProperties : 0}</div></> : null}
                  {data.cardName === "Total Tutomer" ? <><div className='card-count'>{this.state.dashBoardCountList.totalTutomerCount ? this.state.dashBoardCountList.totalTutomerCount : 0}</div></> : null}
                </div>
              </div>
            </div>
          ))}
        </div>
        
      </>
    )
  }
}
