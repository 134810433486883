import BaseDomainService from "./baseDomainServices";

export default class ChemIndexDashBoardServices extends BaseDomainService {
  
    getChemIndexDashboard = this.apiService.chemIndexDashBoardServices.getChemIndexDashboardCounts;


    onGetChemIndexDashboardCounts = (request) => {
      
        this.apiService.onAPICall("getChemIndexDashboard", this.getChemIndexDashboard, request, this.onAPIResponse, this.onUpdateState);
    }

    getAllChemIndexCardList = [
        {
            cardName: 'Total Chem Product',
        },
        {
            cardName: 'Total Pubchem',     
        },
        {
            cardName: 'Total Chem Properties',   
        },
        {
            cardName: 'Total Tutomer', 
        },

    ]
 
    dashBoardCountList;
    onAPIResponse = (key, isSuccess, responseData) => {
   
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getChemIndexDashboard":
           ;
                this.onUpdateState({
                    dashBoardCountList: responseData,
                    "setIsLoad": false
                });
                break;
                default:
                    // Handle default case here
                    break;
        }
    }
}